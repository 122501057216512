<template>
  <div class="message-rule">
    <Header />
    <div v-for="item in list" :key="item" class="content">
      {{item}}
    </div>
  </div>
</template>

<script>
import Header from '@/components/header';
export default {
  components: {
    Header,
  },
  data() {
    return {
      list: [
        '尊敬的用户：史丹利提醒您，在使用本系统服务之前，请仔细阅读本声明的下列条款：',
        '第一条本系统尊重并保护所有用户的个人隐私，对于用户的账号等相关个人资料，非经用户许可或根据相关法律的强制性规定，本系统不会主动地泄露给第三方。',
        '第二条未经本公司允许，禁止将本系统的任何内容泄露给第三方；否则，将承担由此产生的一切法律责任。',
        '第三条如因用户将个人密码告知他人或与他人共享账户而导致个人资料泄露的，本公司对由此产生的损失不承担任何责任。',
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.message-rule {
  .content {
    font-size: 12px;
    color: #616266;
    margin-top: 12px;
    padding: 0 24px;
    text-align: left;
    font-weight: 400;
    line-height: 17px;
    color: #616266;
  }
}
</style>